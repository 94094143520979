import React from 'react'
import { graphql } from 'gatsby'
import ReactHtmlParser from 'react-html-parser'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { isSafari } from 'react-device-detect'
import { getParagraphs } from '../paragraphs/get-paragraphs'
import SEO from '../components/seo'
import * as portfolioStyles from '../styles/modules/post.module.scss'
import * as buttonStyles from '../styles/modules/button.module.scss'
import { wysiwyg } from '../styles/modules/wysiwyg.module.scss'

const PortfolioDetailTemplate = ({ data }) => {
  const post = data.nodePortfolio
  const postWysiwygElements = new ReactHtmlParser(post.body.processed)
  const paragraphs = post.relationships.paragraphs.map(getParagraphs)
  const image = getImage(post.relationships.field_image.localFile)
  const imageAlt = post.field_image.alt || ''
  const imageUrl = `https://admin.redfinsolutions.com${post.relationships.field_image.uri.url}`

  return (
    <>
      <SEO
        title={post.title}
        description={post.body.summary}
        image={imageUrl}
        imageAlt={imageAlt}
      />
      <div className={portfolioStyles.header}>
        <GatsbyImage image={image} alt={imageAlt} className={portfolioStyles.image} />
        <div className={portfolioStyles.info}>
          <h1>{post.title}</h1>
          <div className={portfolioStyles.summary}>{post.body.summary}</div>
        </div>
      </div>
      <article className={portfolioStyles.article}>
        <div className={wysiwyg}>
          <div className={isSafari ? 'safari' : 'not-safari'}>{postWysiwygElements}</div>
          <div>{paragraphs}</div>
        </div>
        <a
          className={`${buttonStyles.button} ${portfolioStyles.button}`}
          target="_blank"
          rel="noopener noreferrer"
          href={post.field_link.uri}
        >
          Visit Project
        </a>
      </article>
    </>
  )
}

export const query = graphql`
  query($id: String!) {
    nodePortfolio(id: { eq: $id }) {
      title
      created
      id
      created
      title
      field_link {
        uri
      }
      field_image {
        alt
      }
      relationships {
        uid {
          name
        }
        paragraphs: field_paragraphs {
          type: __typename
          ...TextParagraphQuery
          ...ImageWithCaptionParagraphQuery
        }
        field_image {
          uri {
            url
          }
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
      body {
        processed
        summary
      }
    }
  }
`

export default PortfolioDetailTemplate
